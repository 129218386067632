<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
          `${$options.servicePrefix} ${item['@id']}`
        }}</v-toolbar-title>
      </template>
    </Toolbar>

    <br />

    <div v-if="item" class="table-evenement-show">
      <v-simple-table>
        <template slot="default">

          <tbody>
            <tr>
              <!-- <tb><strong>{{ $t('nom') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["nom"] }}
                </v-col>
              </tb>

              
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  <tb><strong>{{ $t('Date de début :  ') }}</strong></tb>
                  {{ formatDateTime(item["dateDebut"], "long") }}
                </v-col>
              </tb>

              
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  <tb><strong>{{ $t('Date de fin :  ') }}</strong></tb>
                  {{ formatDateTime(item["dateFin"], "long") }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('sites') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["sites"].nom }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('animations') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["animations"].nom }}
                </v-col>
              </tb>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';

const servicePrefix = 'Evenement';

export default {
  name: 'EvenementShow',
  servicePrefix,
  components: {
      Loading,
      Toolbar
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields('evenement', {
      isLoading: 'isLoading'
    }),
    ...mapGetters('evenement', ['find'])
  },
  methods: {
    ...mapActions('evenement', {
      deleteItem: 'del',
      reset: 'reset',
      retrieve: 'load'
    })
  }
};
</script>
